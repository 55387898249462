import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import HowWeWork from "../../Component/HowWeWork/HowWeWork";
import CallAvailable from "../../Component/CallAvailable/CallAvailable";
import NeedAssistance from "../../Component/NeedAssistance/NeedAssistance";
import OurPurpose from "../../Component/OurPurpose/OurPurpose";
import TeamMembers from "../../Component/TeamMembers/TeamMembers";
import "./AboutPage.css";

function AboutPage() {
  return (
    <div className="d-margin">
      <Container>
        <div className="HProductList">
          <h2>Locally Owned & Managed</h2>
        </div>
        <div className="my-80">
          <HowWeWork />
        </div>
        <TeamMembers />
        <OurPurpose />
        <Row>
          <Col lg={6}>
            <NeedAssistance />
          </Col>
          <Col lg={6}>
            <CallAvailable />
          </Col>
        </Row>
        <Container fluid className="company-logos-section">
          <Row className="justify-content-center text-center ">
            <Col xs={12} className="mb-5">
              <h3 className="company-logos-title">
                Explore our family of companies
              </h3>
            </Col>
            <Col xs={4} sm={3} md={2} className="logo-container">
              <a
                href="https://kingsurplus.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://kingsurplus.com/assets/images/logo.webp"
                  alt="KingSurplus"
                  className="company-logo"
                />
              </a>
            </Col>
            <Col xs={1}></Col>
            <Col xs={4} sm={3} md={2} className="logo-container">
              <a
                href="https://procontainers.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://procontainers.com/assets/images/logo.webp"
                  alt="ProContainers"
                  className="company-logo"
                />
              </a>
            </Col>
            {/* <Col xs={4} sm={3} md={2} className="logo-container">
            <a
              href="https://company3.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/assets/images/company3-logo.png"
                alt="Company 3"
                className="company-logo"
              />
            </a>
          </Col> */}
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default AboutPage;
